.nav-tab-container {
  min-width: 100%;
  overflow: hidden;
}

.navigation-container {
  @include el-light-border-bottom;

  align-items: flex-end;
  display: flex;
  height: 80px;
  justify-content: flex-start;
  min-width: 100%;

  @include media-breakpoint-up(md) {
    border-bottom: 0;
    justify-content: normal;
    min-width: auto;
  }

  @include media-breakpoint-up(lg) {
    display: block;
    height: auto;
    padding-top: 40px;
  }

  .nav-tab-link {
    align-items: flex-end;
    background-color: $white;
    display: flex;
    height: 70px;
    padding: 0 0 4px;
    position: relative;
    transition: left ease-in-out 200ms;

    @include media-breakpoint-up(md) {
      padding: 0 15px 4px;
    }

    @include media-breakpoint-up(lg) {
      display: inline;
      padding: 0 15px 4px;
    }

    @include media-breakpoint-up(xl) {
      padding: 0 16px 4px;
    }

    &.tablet-long-nav {
      @include media-breakpoint-between(xs, md) {
        padding: 0 0 4px;
      }

      @include media-breakpoint-only(md) {
        padding: 0 12px 4px;
      }
    }

    .nav-tab {
      display: inline-block;
      font-weight: $font-weight-semibold;
      padding-bottom: 4px;
      text-align: center;
      white-space: nowrap;

      @include media-breakpoint-between(xs, md) {
        width: 100%;
      }

      .nav-tab-icon {
        margin-bottom: 4px;
      }

      p {
        font-size: 0.875rem;
        margin-bottom: 0;

        @include media-breakpoint-between(xs, lg) {
          color: $lighter-gray-text;
        }

        @include media-breakpoint-up(lg) {
          font-size: 1.125rem;
        }

        @include media-breakpoint-up(xl) {
          font-size: 1.3125rem;
        }
      }
    }
  }

  .current-page {
    border-bottom: 4px solid $primary-brand-color;

    @include media-breakpoint-between(xs, lg) {
      padding-bottom: 0;
    }
  }

  .one-fourth {
    @include media-breakpoint-between(xs, md) {
      min-width: 25%;
    }

    &:nth-child(2) {
      margin-left: 0;
    }
  }

  .one-third {
    @include media-breakpoint-between(xs, md) {
      width: 33.33%;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  .one-fifth {
    @include media-breakpoint-between(xs, md) {
      width: 20%;
    }
  }

  .nav-more-link {
    position: absolute;
    z-index: $zindex-default;
  }

  .more-link-left {
    left: 0;
  }

  .more-link-right {
    right: 0;
  }
}

.nav-arrow-list {
  max-width: 620px;
  padding: 40px 0 0;

  @include media-breakpoint-up(md) {
    padding: 0 0 0 40px;
  }

  ul {
    &.nav-arrow {
      list-style-image: url("../svg/nav-arrow-blue.svg");
    }
  }

  .hollow-cta {
    padding: 10px 0 0 10px;
  }
}
