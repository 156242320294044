// stylelint-disable at-rule-empty-line-before
// stylelint-disable scss/double-slash-comment-empty-line-before

// Base Bootstrap imports.
@import "bootstrap_required";

// Include any other Bootstrap components as needed (all are optional).
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/accordion";
// @import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/alert";
// @import "~bootstrap/scss/progress";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
// @import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/spinners";
// @import "~bootstrap/scss/offcanvas";
// @import "~bootstrap/scss/placeholders";
@import "~bootstrap/scss/helpers";

// Include utilities Bootstrap API last to generate classes based on the Sass map in `_utilities.scss`
@import "~bootstrap/scss/utilities/api";

// Add additional custom code here

// Third-party dependency style imports
@import "~@glidejs/glide/dist/css/glide.core";
@import "~cropperjs/dist/cropper";
@import "~flatpickr/dist/flatpickr";
@import "~tom-select/dist/scss/tom-select.bootstrap5";

// Hide the reCAPTCHA badge

.grecaptcha-badge {
  visibility: hidden;
}

// Google Autocomplete and SearchBox widgets
//
// See https://developers.google.com/maps/documentation/javascript/places-autocomplete#style-autocomplete

.pac-container {
  z-index: $zindex-popover; // Set above exit popups and modals.

  .pac-item {
    font-size: 1.125rem;
    line-height: 57px;

    .pac-icon-marker {
      background-position: -1px -266px;
    }

    .pac-icon {
      background-size: 57px;
      height: 35px;
      width: 26px;
    }

    .pac-item-query {
      font-size: 1.125rem;
    }
  }
}

// Osano
//
// See https://docs.osano.com/css-for-customizing-/-styling-osano-cmp

.osano-cm-widget {
  display: none !important;
}

.osano-cm-link {
  text-decoration: none;
}
