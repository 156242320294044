.btn {
  --#{$prefix}btn-font-weight: #{$font-weight-semibold};

  &.mt-text-color-primary {
    color: var(--primary-brand-color);

    &:hover {
      color: var(--primary-brand-color);
    }
  }

  &.mt-text-color-secondary {
    color: var(--secondary-brand-color);

    &:hover {
      color: var(--secondary-brand-color);
    }
  }

  &.small-button {
    font-size: 16px;
    line-height: 24px;
    margin-left: 3px;
    margin-right: 3px;
    min-width: 100px;
    padding: 5px 20px;
  }
}

.btn-lg {
  --#{$prefix}btn-padding-y: 14px;
  --#{$prefix}btn-font-size: 18px;
}

.btn-group-lg {
  > .btn {
    --#{$prefix}btn-padding-y: 14px;
    --#{$prefix}btn-font-size: 18px;
  }
}

.btn-outline-primary {
  &.active,
  &:active,
  &:focus-visible,
  &:hover {
    .recolor {
      &.primary {
        filter: brightness(0) saturate(100%) invert(1);
      }
    }
  }
}

.btn-outline-secondary,
.btn-outline-dark {
  &.active,
  &:active,
  &:focus-visible,
  &:hover {
    &:not(.btn-greyed) {
      img {
        &.recolor {
          filter: brightness(0) saturate(100%) invert(1);
        }
      }

      .sharing-url {
        color: $white;
      }
    }
  }
}

.btn-link {
  --#{$prefix}btn-font-weight: #{$font-weight-normal};

  &.no-padding {
    min-width: 0;
    padding: 0;
    width: auto;
  }

  img {
    margin: 0 2px;
  }
}

.btn-outline-light {
  &:hover {
    background-color: transparent;
    border-color: $light-text-color;
    color: $light-text-color;
  }
}

.btn-outline-private-alert-red {
  @include button-outline-variant(
    $private-alert-red,
    $color-hover: $white,
    $active-background: $private-alert-red,
    $active-border: $private-alert-red,
    $active-color: $white
  );
}

.btn-facebook {
  @include button-variant($facebook-blue, $facebook-blue);
}

.btn-htmx {
  &.htmx-request {
    .active-text {
      display: none;
    }

    .working-text {
      display: inline;
    }
  }

  .active-text {
    display: inline;
  }

  .working-text {
    display: none;
  }
}
