// Animations

@keyframes slide-down {
  from {
    opacity: 0.25;
    top: -20px;
  }

  to {
    opacity: 1;
    top: 0;
  }
}

@keyframes visible {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// Inspired by https://github.com/animate-css/animate.css
//
// TODO: Consider adding the library directly since we can now import single modules

:root {
  --animate-duration: 1s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animate__animated {
  animation-duration: 1s;
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

@keyframes pulse {
  // stylelint-disable-next-line keyframe-selector-notation
  from {
    transform: scale3d(1, 1, 1);
  }

  50% {
    transform: scale3d(1.25, 1.25, 1.25);
  }

  // stylelint-disable-next-line keyframe-selector-notation
  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate__pulse {
  animation-name: pulse;
  animation-timing-function: ease-in-out;
}

// stylelint-disable-next-line keyframes-name-pattern
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// stylelint-disable-next-line selector-class-pattern
.animate__fadeIn {
  animation-name: fadeIn;
}

// stylelint-disable-next-line keyframes-name-pattern
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

// stylelint-disable-next-line selector-class-pattern
.animate__fadeOut {
  animation-name: fadeOut;
}

.htmx-animate-transition {
  opacity: 1;
  transition: opacity 300ms ease-in-out;

  &.htmx-request {
    opacity: 0.5;
  }

  &.htmx-added,
  &.htmx-swapping {
    opacity: 0;
  }
}
