.borderless-add-button {
  appearance: none;
  background: none;
  border: 0;
  color: $primary-brand-color;
  cursor: pointer;
  font-weight: $font-weight-semibold;
  line-height: normal;
  overflow: visible;
  padding: 0;
  user-select: none;
}

.borderless-input {
  @include el-border-bottom;

  background: transparent;
  border-left: 0;
  border-right: 0;
  border-top: 0;
  font-size: 1.125rem;
  width: 100%;

  @include media-breakpoint-between(xs, md) {
    max-width: 335px;
    padding: 0 0 1px;
  }

  @include media-breakpoint-up(md) {
    width: $el-input-length;
  }

  &.flatpickr-input {
    background-color: $white;
    border-radius: 0;
    padding-left: 0;
  }

  &.disabled-input {
    background-color: $light-gray-with-90-percent-opacity;
    color: $lighter-gray-text;
  }
}

.btn-form-submit {
  text-align: right;

  @include media-breakpoint-between(xs, md) {
    padding-top: 20px;
    text-align: center;
    width: 100%;
  }

  &.btn-form-submit-left {
    text-align: center;

    @include media-breakpoint-up(md) {
      text-align: left;
    }
  }

  .btn {
    width: 100%;

    @include media-breakpoint-up(md) {
      width: $el-button-length;
    }
  }

  &.multiple-btn {
    .btn {
      margin: 0 0 20px;
      width: $el-button-length;

      &:last-child {
        margin-bottom: 0;
        margin-right: 0;
      }

      @include media-breakpoint-up(md) {
        margin: 0 20px 0 0;
        width: auto;
      }
    }
  }
}

.character-limit {
  color: $lighter-gray-text;
  font-size: 0.875rem;
  margin-left: 8px;

  &.orange {
    color: $warning-orange;
  }

  &.red {
    color: $red;
  }
}

.custom-select-with-caret {
  @include el-border;

  background-color: $white;
  color: $dark-gray-text;
  position: relative;
  width: $el-input-length;

  &:not(.disabled) {
    &::before {
      filter: var(--primary-brand-color-svg);
    }
  }

  &.disabled {
    &:hover {
      background-color: $white;
    }

    &::before {
      background: url("../svg/caret-down-gray.svg") no-repeat right 16px center / 27px
        16px;
    }
  }

  &::before {
    background: url("../svg/caret-down.svg") no-repeat right 16px center / 27px 16px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }

  &.rounded {
    border-radius: $input-border-radius;
  }

  &.small-select {
    width: auto;

    select {
      border: 0;
      font-size: 1.1rem;
      font-weight: $font-weight-semibold;
      line-height: 1.5;
      padding: 8px 10px 8px 20px;
    }
  }

  &.very-small-select {
    position: relative;
    width: auto;

    &.disabled {
      &::before {
        background: url("../svg/caret-down-gray.svg") no-repeat right 20px center / 18px
          11px;
      }
    }

    &::before {
      background: url("../svg/caret-down.svg") no-repeat right 20px center / 18px 11px;
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    select {
      border: 0;
      font-size: 0.975rem;
      font-weight: $font-weight-normal;
      line-height: 1.3;
      padding: 10px 18px;

      @include media-breakpoint-up(md) {
        padding: 2px 6px 2px 12px;
      }
    }
  }

  &:hover {
    background-color: $table-hover-gray;
  }

  select {
    @extend %base-h3;

    appearance: none;
    background: transparent;
    background-image: none;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    color: $darker-gray-text;
    margin-bottom: 0;
    padding: 20px;
    position: relative;
    width: 100%;

    &.disabled-input {
      background-color: $light-gray-with-90-percent-opacity;
      color: $lighter-gray-text;
    }
  }
}

.el-animated-placeholder-label-input {
  margin-bottom: -34px;
  padding-bottom: 8px;

  &.validation-failed {
    input,
    textarea {
      border: 1px solid $red;
      box-shadow: inset 0 0 0 1px $red;
    }
  }

  &.keep-margin-bottom {
    margin-bottom: 0;
  }

  &.no-label {
    .custom-select-with-caret {
      &.small-select {
        select {
          font-weight: $font-weight-semibold;
          height: 52px;
          padding: 12px 60px 12px 12px;
        }
      }

      &.very-small-select {
        select {
          font-weight: $font-weight-semibold;
          height: 47px;
          padding: 6px 10px;
        }
      }
    }
  }

  textarea {
    background-color: $white;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;

    &.has-focused {
      &.required {
        &:not(:focus) {
          border: 1px solid $red;
          box-shadow: inset 0 0 0 1px $red;
        }
      }
    }

    &:-webkit-autofill {
      background-color: transparent;
      filter: none;
    }
  }

  input {
    background-color: $white;
    border: 1px solid $input-border-color;
    border-radius: $input-border-radius;
    margin-bottom: 10px;
    padding: 10px 8px;
    transition: all 0.15s ease-out;
    width: 100%;

    &.has-focused {
      &.required {
        &:not(:focus) {
          border: 1px solid $red;
          box-shadow: inset 0 0 0 1px $red;
        }
      }
    }

    &:-webkit-autofill {
      background-color: transparent;
      filter: none;
    }
  }

  label {
    color: $gray-600;
    display: inline-block;
    left: 10px;
    margin: 0 auto;
    pointer-events: none;
    position: relative;
    top: -46px;
    transition: all 0.15s ease-out;

    @include media-breakpoint-only(md) {
      font-size: 0.9375rem;
    }
  }

  span {
    &.character-limit {
      font-size: 1rem;
      position: absolute;
      right: 10px;
      top: 12px;
      transition: all 0.15s ease-out;
    }
  }

  .errorlist {
    margin-top: -46px;
  }

  .custom-select-with-caret {
    border-radius: $input-border-radius;
    margin-bottom: 10px;

    &:disabled {
      &:hover {
        background-color: $white;
      }
    }

    &:focus-within {
      border: 1px solid $secondary-brand-color;
      box-shadow: inset 0 0 0 1px $secondary-brand-color;
    }

    &.has-focused {
      &.required {
        &:not(:focus-within) {
          border: 1px solid $red;
          box-shadow: inset 0 0 0 1px $red;
        }
      }
    }

    &.small-select {
      select {
        height: 52px;
        overflow-x: hidden;
        padding: 18px 60px 12px 8px;
        text-overflow: ellipsis;
        vertical-align: bottom;
      }

      + label {
        top: -66px;
      }
    }

    &.very-small-select {
      min-width: 100px;

      select {
        height: 47px;
        padding: 14px 8px 6px;
      }

      &.relationship-type-select {
        select {
          height: 60px;
          padding: 25px 8px 6px;
        }

        + label {
          top: -70px;
        }
      }
    }

    select {
      overflow-y: hidden;
    }

    + label {
      font-size: 0.75rem;
      text-transform: uppercase;
      top: -62px;
    }
  }

  &.filled {
    input {
      padding-bottom: 2px;
      padding-top: 18px;
    }

    label {
      font-size: 0.6375rem;
      text-transform: uppercase;
      top: -60px;

      @include media-breakpoint-up(lg) {
        font-size: 0.75rem;
      }
    }

    .character-limit {
      font-size: 0.75rem;
      text-transform: uppercase;
      top: 3px;
    }
  }
}

.el-checkbox {
  display: flex;
  margin: 0;
  position: relative;

  &.big-checkbox {
    input {
      &[type="checkbox"] {
        height: 40px;
        width: 40px;

        &:checked {
          ~ .pseudo-checkbox {
            &::before {
              background-size: 28px auto;
            }
          }
        }
      }
    }

    .pseudo-checkbox {
      border-radius: $border-radius-lg;
      height: 36px;
      min-width: 36px;
    }
  }

  label {
    cursor: pointer;
    margin-bottom: 0;
  }

  input {
    &[type="checkbox"] {
      cursor: pointer;
      height: 25px;
      opacity: 0;
      position: absolute;
      width: 25px;

      &:checked {
        ~ .pseudo-checkbox {
          pointer-events: none;
          position: relative;

          &::before {
            background-image: url("../svg/checkmark-primary.svg");
            background-position: center;
            background-repeat: no-repeat;
            background-size: 16px auto;
            content: "";
            height: 100%;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
          }
        }
      }

      &.disabled,
      &:disabled,
      &[readonly] {
        ~ .pseudo-checkbox {
          background-color: $input-disabled-bg;

          // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
          opacity: 1;
        }
      }
    }
  }

  + label {
    cursor: pointer;
  }

  .label-gray {
    color: $lighter-gray-text;
  }

  .pseudo-checkbox {
    align-content: flex-start;
    border: $darker-gray solid 2px;
    border-radius: $border-radius-sm;
    display: inline-block;
    height: 20px;
    margin: 5px 8px 2px 0;
    min-width: 20px;
    vertical-align: text-bottom;

    &::before {
      filter: var(--primary-brand-color-svg);
    }
  }
}

.el-input-group {
  margin-bottom: 40px;

  &.event-type {
    label {
      display: block;
      margin: 0 20px 0 0;

      @include media-breakpoint-up(md) {
        display: inline;
        margin: 0 0 20px;
      }

      span {
        padding-right: 6px;
      }
    }
  }

  &.estate-executor,
  &.gender {
    .el-radio-btn {
      padding-bottom: 20px;

      @include media-breakpoint-up(md) {
        padding-bottom: 0;
      }

      &:last-of-type {
        padding-bottom: 0;
      }

      label {
        align-items: center;
        margin-right: 20px;
      }
    }
  }

  &.relationship {
    .relationship-other {
      margin-top: 20px;
    }
  }
}

.el-radio-btn {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 10px;
  position: relative;
  text-align: left;

  @include media-breakpoint-up(md) {
    align-items: center;
  }

  label {
    align-items: center;
    cursor: pointer;
    display: flex;
    margin-bottom: 0;
    margin-top: -4px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }

    &.align-items-start {
      align-items: flex-start;

      .select,
      .select-border-checked,
      .select-border-unchecked {
        margin-top: 4px;
      }
    }

    .radio-label {
      margin-left: 10px;
      padding-left: 10px;
    }
  }

  input {
    &[type="radio"] {
      cursor: pointer;
      height: 20px;
      margin: 0 auto;
      opacity: 0;
      position: absolute;
      width: 20px;

      &:checked {
        ~ .select {
          &::before {
            background: var(--secondary-brand-color);
            visibility: visible;
          }
        }

        ~ .select-border-checked {
          opacity: 1;
        }
      }
    }
  }

  .select-border-unchecked {
    background: transparent;
    border: 2px solid $darker-gray;
    border-radius: 50%;
    height: 20px;
    margin: 0 auto;
    opacity: 1;
    position: absolute;
    width: 20px;
  }

  .select-border-checked {
    border: 2px solid $darker-gray;
    border-radius: 50%;
    height: 20px;
    margin: 0 auto;
    position: absolute;
    width: 20px;
  }

  .select {
    border: $darker-gray solid 2px;
    border-radius: 50%;
    display: flex;
    height: 20px;
    place-content: flex-start center;
    position: relative;
    vertical-align: text-bottom;
    visibility: hidden;
    width: 20px;

    &::before {
      align-self: center;
      border: 5px solid $white;
      border-radius: 50%;
      content: "";
      display: flex;
      justify-content: center;
      min-height: 20px;
      min-width: 20px;
      transition: background 0.2s linear;
      visibility: hidden;
    }
  }
}

// Django form errors

.errorlist {
  color: $red;
  font-size: 1.125rem;
  list-style: none;
  padding-left: 0;
  padding-top: 20px;
}

// Tweak to BS5 textarea "label" placeholder to lighten the color.
// TODO: Check in the future to see if they ever make this a configurable variable.

.form-floating {
  > .form-control,
  > .form-select {
    ~ label {
      opacity: $form-floating-label-opacity;
    }
  }

  + .errorlist {
    font-size: 0.85rem;
    padding-top: 0.25rem;

    > li {
      margin-bottom: 0.15rem;
    }
  }

  + .errorlist-container {
    .errorlist {
      font-size: 0.85rem;
      padding-top: 0.25rem;

      > li {
        margin-bottom: 0.15rem;
      }
    }
  }

  span {
    &.character-limit {
      font-size: 1rem;
      position: absolute;
      right: 10px;
      top: 12px;
      transition: all 0.15s ease-out;
    }
  }
}

.form-check-input {
  margin-top: 0.3em;
}

.form-control,
.form-select {
  font-size: 1.125rem;

  &:focus {
    border-color: var(--primary-brand-color-muted) !important;
    box-shadow: 0 0 0 0.25rem var(--primary-brand-color-muted) !important;
  }
}

.hidden-submit {
  background: none;
  border: 0;
  left: 0;
  margin: 0;
  max-height: 0;
  max-width: 0;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
}

.rounded-field-wrapper {
  border: 1px solid $border-color;
  border-radius: $border-radius-sm;
  padding: 10px 8px !important;

  &.focused {
    border: 1px solid $secondary-brand-color;
    box-shadow: inset 0 0 0 1px $secondary-brand-color;
  }
}

.search-business-wrapper {
  .organ-donation-input-group {
    &.el-input-group {
      align-items: flex-start;
      flex-direction: column;
      padding-bottom: 50px;

      .el-checkbox-wrapper {
        margin-bottom: 10px;
        margin-top: 10px;
      }
    }
  }
}

.autocomplete-option {
  > span {
    &:first-of-type {
      font-weight: $font-weight-semibold;
    }

    &:not(:first-of-type) {
      color: $lighter-gray-text;
      font-size: 15px;
    }
  }
}

.choice-button {
  border: 2px solid $border-color;
  border-radius: $border-radius-lg;
  cursor: pointer;
  margin-bottom: 10px;
  padding: 10px;
  text-align: left;
  transition: all 200ms ease-in-out;

  &.selected,
  &:hover {
    border-color: var(--secondary-brand-color);
  }

  &.btn {
    background-color: $white;
    color: $dark-gray-text;
    padding: 14px;
  }
}

.input-group {
  &.input-group-embedded {
    &:focus-within {
      .input-group-text {
        border-color: var(--primary-brand-color-muted) !important;
        box-shadow: 0 0 0 0.25rem var(--primary-brand-color-muted) !important;
      }
    }

    .input-group-text {
      border-right: 0;
      clip-path: inset(-6px 0.5px -6px -6px);
      transition: all 0.15s ease-in-out;
    }

    input {
      border-left: 0;
      clip-path: inset(-6px -6px -6px 0.5px);
    }
  }
}

// Override TomSelect SCSS with CSS-var-based colors to support theming and other
// style tweaks.
.ts-control {
  .focus & {
    border-color: var(--primary-brand-color-muted) !important;
    box-shadow: 0 0 0 0.25rem var(--primary-brand-color-muted) !important;
  }

  .item {
    &.active {
      background: var(--primary-brand-color) !important;
      border: 0 !important;
    }
  }
}

// stylelint-disable-next-line no-duplicate-selectors
.input-group {
  .ts-control {
    border-left: 0;
  }
}

.ts-wrapper {
  &:not(.allow-wrap) {
    .ts-control {
      // Disable wrapping of long names/items onto multiple lines.
      flex-wrap: nowrap;

      .item {
        // Allow the selected item to flex-shrink when it's too long.
        flex-shrink: 1;
        white-space: nowrap;
      }
    }
  }

  &.form-control {
    &.ts-search {
      .ts-control {
        &::before {
          background: url("../svg/magnifying-glass-gray.svg") no-repeat left center /
            1rem 1rem !important;
          content: "";
          height: 1rem;
          width: 1.5rem;
        }
      }
    }
  }

  &:not(.form-control) {
    .ts-control {
      &::after {
        content: "";
        height: 1rem;
        width: 1.75rem;
      }
    }
  }
}
