// Links
//
// TODO: These should probably be refactored away via other utility classes

.link-primary,
.link-large,
.link-style-text {
  color: var(--primary-brand-color) !important;

  &:hover {
    // NOTE: We've disabled link hover for now
    // color: shade-color($primary, $link-shade-percentage) !important;
    color: var(--primary-brand-color) !important;
  }
}

.link-secondary {
  color: var(--secondary-brand-color) !important;
  font-size: 1.3125rem;
  font-weight: $font-weight-semibold;

  &:hover {
    // NOTE: We've disabled link hover for now
    // color: shade-color($secondary, $link-shade-percentage) !important;
    color: var(--secondary-brand-color) !important;
  }

  img {
    margin-left: 8px;
    margin-top: -1px;
    width: 20px;
  }
}

.link-large {
  display: block;
  font-size: 1.3125rem;
  word-break: break-word;
  word-wrap: break-word;
}

.link-style-text {
  color: $primary-brand-color;
  font-weight: $font-weight-semibold;
}
