// Helper classes

.click-capture-overlay {
  cursor: help;
}

.cover-reposition {
  &.portrait {
    object-position: center 10%;
  }
}

.flex-50 {
  flex-basis: 50% !important;
}

.flex-100 {
  flex-basis: 100% !important;
}

.flex-equal {
  flex: 1 1 0 !important;
}

.no-scroll {
  overflow: hidden;
  position: fixed;
  width: 100%;
}

.ratio-md {
  @include media-breakpoint-up(md) {
    position: relative;
    width: 100%;

    &::before {
      content: "";
      display: block;
      padding-top: var(--#{$prefix}aspect-ratio);
    }

    > * {
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }
}

.ratio-8x3 {
  --#{$prefix}aspect-ratio: 37.5%;
}

.recolor {
  &.primary {
    filter: var(--primary-brand-color-svg);
  }

  &.secondary {
    filter: var(--secondary-brand-color-svg);
  }

  &.light-gray {
    filter: $svg-recolor-light-gray;
  }

  &.gray {
    filter: $svg-recolor-dark-gray-text;
  }

  &.darker-gray {
    filter: $svg-recolor-darker-gray;
  }

  &.black {
    filter: brightness(0) saturate(100%);
  }

  &.white {
    filter: brightness(0) saturate(100%) invert(1);
  }

  &.green {
    filter: $svg-recolor-green;
  }

  &.orange {
    filter: $svg-recolor-orange;
  }

  &.info {
    filter: $svg-recolor-info;
  }
}

.border-primary {
  border-color: var(--primary-brand-color) !important;
}

.bg-primary {
  background-color: var(--primary-brand-color) !important;
}

.text-bg-primary {
  background-color: var(--primary-brand-color) !important;

  &.muted {
    background-color: var(--primary-brand-color-muted) !important;
  }

  // TODO: What is this for?
  img {
    margin: -0.2rem 0.15rem 0 0.05rem;
  }
}

.text-bg-secondary {
  background-color: var(--secondary-brand-color) !important;
}

.text-bg-private-alert-red {
  // NOTE: The Bootstrap color-contrast function thinks $private-alert-red should have
  // black foreground text so we're hardcoding $white for now.
  background-color: rgba(to-rgb($private-alert-red), var(--#{$prefix}bg-opacity, 1))
    if($enable-important-utilities, !important, null);
  color: $white !important;
  // stylelint-disable-next-line scss/double-slash-comment-empty-line-before
  // color: color-contrast($private-alert-red) if($enable-important-utilities, !important, null);

  img {
    margin: -0.2rem 0.15rem 0 0.05rem;
  }
}

.opacity-90 {
  opacity: 0.9 !important;
}

.position-md-sticky {
  @include media-breakpoint-up(md) {
    // stylelint-disable-next-line value-no-vendor-prefix
    position: -webkit-sticky !important;
    position: sticky !important;
  }
}

.working-overlay {
  display: none;
  z-index: $zindex-fixed;

  &.htmx-request {
    cursor: wait;
    display: flex;
  }
}
