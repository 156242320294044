.error-page {
  background-color: $white;

  .error-page-content {
    background-color: $white;
    background-image: url("../img/404_illustration_mobile.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 809px;

    @include media-breakpoint-up(md) {
      background-image: url("../img/404_illustration_tablet.png");
      min-height: 773px;
    }

    @include media-breakpoint-up(lg) {
      background-image: url("../img/404_illustration_desktop.png");
      min-height: 903px;
    }

    h1 {
      margin-bottom: 0;
      padding-top: 40px;

      @include media-breakpoint-up(md) {
        padding-top: 80px;
      }

      @include media-breakpoint-up(lg) {
        padding-top: 120px;
      }
    }

    .error-page-subheader {
      padding-top: 20px;
    }

    .error-page-list-header {
      padding-top: 40px;
    }

    .error-page-list {
      padding-top: 20px;
    }

    .error-page-list-item {
      padding-top: 10px;
    }

    .container {
      padding: 0 20px;
    }
  }

  footer {
    .footer-container {
      background-color: $white;
      margin-top: 0;
    }
  }
}
