.el-progress {
  flex: 1 1 auto;

  progress {
    // reset the default appearance
    appearance: none;

    // set the progress element's container background for Firefox
    background-color: $light-gray;
    border: 1px solid $medium-gray;

    // set the progress element's value color for Internet Explorer
    color: var(--secondary-brand-color);
    height: 20px;
    width: 100%;

    // Webkit/Blink browsers provide two pseudo classes that can be used to
    // style the progress element's value and container.
    // -webkit-progress-bar -> style the progress element's container
    // -webkit-progress-value -> style the progress element's value.

    &::-webkit-progress-bar {
      background-color: $light-gray;
    }

    &::-webkit-progress-value {
      background-color: var(--secondary-brand-color);
    }

    // Firefox provides a single pseudo class that can be used to style the
    // progress element's value but not its container.

    &::-moz-progress-bar {
      background-color: var(--secondary-brand-color);
    }

    // IE likes to add this weird black vertical bar to the end of the progress
    // value.

    &::-ms-fill {
      border: 0;
    }
  }

  &.rounded-progress {
    progress {
      background-color: $medium-gray;
      border: 0;
      border-radius: $border-radius-lg;
      height: 10px;

      &::-webkit-progress-bar {
        background-color: $medium-gray;
        border-radius: $border-radius-lg;
      }

      &::-webkit-progress-value {
        border-radius: $border-radius-lg;
      }

      &::-moz-progress-bar {
        border-radius: $border-radius-lg;
      }
    }
  }

  &.animate {
    progress {
      &::-webkit-progress-value {
        transition: width 550ms linear;
      }

      [value] {
        &::-moz-progress-bar {
          transition: width 550ms linear;
        }
      }
    }
  }
}

.progress-ring {
  &.primary {
    .progress-ring__circle {
      stroke: var(--primary-brand-color);
    }

    .progress-ring__label {
      fill: var(--primary-brand-color);
    }
  }
}

.progress-ring__background-circle {
  stroke: $gray-400;
}

.progress-ring__circle {
  stroke: var(--secondary-brand-color);
  stroke-dasharray: 10 20;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  transition: stroke-dashoffset 0.35s;
}

.progress-ring__label {
  dominant-baseline: central;
  fill: var(--secondary-brand-color);
  text-anchor: middle;
}
