// Site footer

footer {
  font-size: 0.9375rem;

  &.wide-footer {
    .container {
      @include media-breakpoint-up(md) {
        max-width: 720px !important;
      }

      @include media-breakpoint-up(lg) {
        max-width: 1200px !important;
      }
    }
  }

  &.wider-footer {
    .container {
      @include media-breakpoint-up(md) {
        max-width: 720px !important;
      }

      @include media-breakpoint-up(lg) {
        max-width: 1280px !important;
      }
    }
  }

  &.public-footer {
    padding: 0;

    @include media-breakpoint-up(md) {
      padding: 20px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0 40px;
    }

    .container {
      max-width: 1400px !important;
    }
  }

  &.employee-lp-footer {
    .footer-container {
      margin-top: 0;
    }
  }

  .footer-container {
    @include el-light-border-top;

    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    padding-bottom: 20px;
    padding-top: 40px;

    @include media-breakpoint-up(md) {
      flex-direction: row;
      margin-top: 80px;
    }
  }

  .footer-left-container {
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      justify-content: normal;
      margin-bottom: 0;
      padding: 0;
      width: 540px;
    }

    a {
      margin-bottom: 20px;
      margin-right: 0;
      width: 160px;

      @include media-breakpoint-up(md) {
        margin-right: 20px;
      }
    }
  }

  .footer-right-container {
    flex-direction: column;
    flex-shrink: 0;
    padding: 0 20px;

    @include media-breakpoint-up(md) {
      align-items: flex-end;
      padding: 0;
      width: 335px;
    }

    &.distraction-free-footer {
      align-items: normal;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;

      @include media-breakpoint-up(md) {
        flex-direction: row-reverse;
      }

      .legal-container {
        justify-content: flex-start;

        @include media-breakpoint-up(md) {
          justify-content: normal;
        }

        a {
          &:nth-last-child(2) {
            margin-left: 20px;
          }
        }
      }
    }

    .legal-container {
      flex-wrap: wrap;
      justify-content: flex-end;
      margin-bottom: 20px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
      }

      a {
        margin-bottom: 20px;
        margin-left: 20px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}
