.body-card {
  background-color: $white;
  padding: 20px;

  @include media-breakpoint-up(md) {
    border-radius: $border-radius-lg;
    box-shadow: -1px -1px 5px 0 $black-with-5-percent-opacity;
    margin-bottom: 40px;
    margin-top: 40px;
    padding: 40px;
  }

  &:last-of-type {
    margin-bottom: 0;
  }

  &.body-card-no-padding {
    padding: 0;

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-lg;
    }

    > div {
      padding: 0 20px;

      @include media-breakpoint-up(md) {
        padding: 0 40px;
      }

      &:first-of-type {
        padding-top: 20px;

        @include media-breakpoint-up(md) {
          border-radius: $border-radius-lg $border-radius-lg 0 0;
          padding-top: 40px;
        }
      }

      &:last-of-type {
        padding-bottom: 20px;

        @include media-breakpoint-up(md) {
          border-radius: 0 0 $border-radius-lg $border-radius-lg;
          padding-bottom: 40px;
        }
      }

      &.premium-preference-toggle {
        padding-left: 40px !important;
        padding-right: 40px !important;
      }
    }
  }

  &.mobile-no-padding {
    @include media-breakpoint-between(xs, md) {
      padding: 0;
    }
  }

  &.pending {
    background-color: transparent;
    border: 1px dashed $gray-400;
    box-shadow: none;

    @include media-breakpoint-between(xs, md) {
      border-radius: $btn-border-radius;
    }

    @include media-breakpoint-up(md) {
      background-color: $very-light-gray;
      border: 3px dashed $gray-200;
    }
  }
}

.container {
  padding-left: 0;
  padding-right: 0;

  @include media-breakpoint-between(xs, md) {
    padding-bottom: 20px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 1095px !important;
    padding: 0;
  }
}

.container-narrow {
  margin-left: auto;
  margin-right: auto;
  padding: 0;
  width: 600px;

  @include media-breakpoint-between(xs, md) {
    padding-bottom: 20px;
    width: 100%;
  }
}

.mason-flex-container {
  display: flex;
  flex-flow: column wrap;
  margin-left: -10px;
  max-height: none;
  position: relative;

  > div {
    flex: 0 0 auto;
    margin-bottom: 10px;
    margin-left: 10px;
    width: calc(100% - 10px);

    @include media-breakpoint-up(md) {
      max-width: calc(50% - 10px);
      width: calc(50% - 10px);
    }
  }
}
