.user-post-container {
  margin-top: 20px;

  .user-post-form {
    margin-bottom: 20px;
    width: 100%;

    > div {
      > div {
        &:last-child {
          &:not(.requires-moderation) {
            border-bottom: 0;
          }
        }
      }
    }

    .expanded-text {
      height: 6rem;
    }
  }

  .user-post {
    align-items: flex-start;
    margin-bottom: 20px;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
    }

    .user-post-edit-field {
      border-radius: $border-radius-lg;
      height: 74px;
      width: 100%;
    }

    .user-post-text {
      min-width: 0;

      h3 {
        display: inline;
      }

      .expandable {
        word-break: break-word;
        word-wrap: break-word;
      }
    }

    .read-more {
      color: $primary-brand-color;
      font-weight: $font-weight-semibold;
    }

    .user-post-subtitle {
      margin-top: 10px;

      a {
        color: $lighter-gray-text;
        font-weight: $font-weight-normal;
      }
    }

    .user-post-meta-container {
      font-size: 0.9375rem;
      margin-bottom: 8px;

      .user-profile-photo {
        height: 36px;
        margin: 0 8px 0 0;
        width: 36px;
      }

      .user-post-share-link {
        color: $lighter-gray-text;
        font-weight: $font-weight-normal;
      }
    }
  }

  .answer-text {
    width: 100%;

    @include media-breakpoint-between(xs, md) {
      margin-top: 20px;
      min-width: auto;
    }
  }

  .collapsed-submit-wrapper {
    .collapsed-submit-btn {
      flex-direction: column-reverse;
    }
  }

  button {
    &[type="submit"] {
      margin: 20px 0 10px;
      width: 100%;

      @include media-breakpoint-up(md) {
        width: 335px;
      }
    }
  }
}

.comment-container {
  @include media-breakpoint-up(md) {
    margin: 0 0 0 20px;
  }

  &.empty {
    margin: 0;
  }

  .comment {
    @include el-light-border-bottom;

    margin-top: 15px;
    padding-bottom: 20px;
    position: relative;

    @include media-breakpoint-up(md) {
      margin-top: 20px;
    }

    &.requires-moderation {
      border: 1px dashed $warning-orange;
      border-radius: $border-radius-lg;
      margin: 20px 10px;
      padding: 10px;

      @include media-breakpoint-up(md) {
        margin: 20px 30px;
        padding: 20px;
      }

      @include media-breakpoint-up(lg) {
        margin: 20px -10px;
        padding: 20px 10px 10px;
      }

      .moderation-required-label {
        margin-left: 10px;
      }
    }

    &.hidden-comment {
      border: 1px dashed $gray-500;
      border-radius: $border-radius-lg;
      margin: 20px 10px;
      padding: 10px;

      @include media-breakpoint-up(md) {
        margin: 20px 30px;
        padding: 20px;
      }

      @include media-breakpoint-up(lg) {
        margin: 20px -10px;
        padding: 20px 10px 10px;
      }
    }

    .user-post {
      margin-bottom: 0;
    }
  }

  .answer-text {
    border-radius: $border-radius-lg;
    height: 50px;
  }
}

.edit-menu-dropdown {
  border-radius: $border-radius-sm;
  position: relative;
  z-index: $zindex-dropdown;

  button {
    background: none !important;
    border: 0;
    cursor: pointer;
    font-weight: $font-weight-semibold;
  }

  a,
  button {
    border-bottom: 1px solid $input-border-color;
    border-radius: 0;
    display: block;
    padding: 15px 10px;
    width: 100%;
  }
}

.user-post-edit-controls {
  display: flex;
  position: relative;

  .dropdown-arrow {
    background: $white;
    border-left: 1px solid $input-border-color;
    border-top: 1px solid $input-border-color;
    height: 24px;
    margin-top: 16px;
    position: absolute;
    right: -16%;
    top: -29px;
    transform: rotate(45deg);
    width: 24px;

    @include media-breakpoint-up(md) {
      right: 10%;
    }
  }
}

.user-post-edit-control {
  background: $white;
  border: 1px solid $input-border-color;
  border-radius: $border-radius-sm;
  margin-bottom: 10px;
  margin-top: 15px;
  min-width: 90px;
  position: absolute;
  right: -2px;
  text-align: center;

  @include media-breakpoint-up(md) {
    right: -26px;
  }

  &.edit-confirmation {
    background: none;
    border: 0;
    border-radius: 0;
    bottom: 95px;
    right: 10px;

    @include media-breakpoint-only(md) {
      right: 7px;
    }
  }

  div {
    display: inline;
  }

  a,
  button {
    color: $lighter-gray-text;
    text-transform: uppercase;

    &:hover {
      color: $primary-brand-color;
    }
  }

  a,
  div,
  button {
    font-size: 0.875rem;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  .confirm-delete-user-post {
    @include media-breakpoint-between(xs, md) {
      &:first-child {
        margin-right: 0;
      }
    }
  }

  .cancel-edit-user-post {
    color: $lighter-gray-text;
  }
}

.user-profile-photo {
  border-radius: 50%;
  height: 30px;
  margin: 0 8px 8px 0;
  min-width: 30px;
  object-fit: cover;
  width: 30px;
}

.public-edit-menu-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;

  .public-edit-menu {
    img {
      margin-right: 0 !important;
    }
  }
}

.toggle-dropdown-edit-menu {
  display: flex;
  justify-content: flex-end;

  img {
    cursor: pointer;
  }
}
