@use "sass:color";

// Theme mixins and definitions

:root {
  @include theme-variables(
    $primary-brand-color,
    $secondary-brand-color,
    $svg-recolor-primary-brand-color,
    $svg-recolor-secondary-brand-color
  );
}

@include theme($primary-brand-color, $secondary-brand-color);

.mt-background-color-secondary-light {
  background-color: var(--secondary-brand-color-light) !important;
}

.mt-background-color-primary {
  background-color: var(--primary-brand-color) !important;
}

.mt-background-color-secondary {
  background-color: var(--secondary-brand-color) !important;
}

.mt-background-color-primary-light {
  background-color: var(--primary-brand-color-light) !important;
}

.mt-border-color-primary {
  border-color: var(--primary-brand-color) !important;
}

.mt-border-color-mobile-primary {
  @include media-breakpoint-between(xs, md) {
    border-color: var(--primary-brand-color) !important;
  }
}

.mt-border-color-secondary {
  border-color: var(--secondary-brand-color) !important;
}

.mt-border-color-secondary-light {
  border-color: var(--secondary-brand-color-light) !important;
}

.mt-text-color-primary {
  color: var(--primary-brand-color) !important;

  &:hover {
    color: var(--primary-brand-color) !important;
  }
}

.mt-text-color-secondary {
  color: var(--secondary-brand-color) !important;

  &:hover {
    color: var(--secondary-brand-color) !important;
  }
}

.mt-text-color-active-secondary {
  &:active {
    color: var(--secondary-brand-color) !important;
  }
}

.mt-text-color-hover-secondary {
  &:hover {
    color: var(--secondary-brand-color) !important;
  }
}
