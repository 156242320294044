.pagination-breadcrumbs {
  @include media-breakpoint-between(xs, md) {
    padding: 40px 20px;
  }

  &.low-padding {
    @include media-breakpoint-between(xs, md) {
      padding: 20px 10px;
    }
  }

  ul {
    margin-bottom: 0;
  }

  .page-link {
    border: 1px solid var(--primary-brand-color);
    border-radius: 2px;
    color: var(--primary-brand-color);
    font-size: 14px;
    margin: 0 2.5px;
    padding-top: 11px;
    text-align: center;

    @include media-breakpoint-up(md) {
      margin: 0 5px;
      padding-top: 9px;
    }

    &:hover {
      background-color: var(--primary-brand-color);
      border: 1px solid var(--primary-brand-color);
      color: $white;
    }

    &.current {
      background-color: var(--primary-brand-color);
      color: $white;
    }

    li {
      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .page-link-text {
    height: 44px;
    width: 84px;

    @include media-breakpoint-up(md) {
      height: 40px;
      width: 96px;
    }
  }

  .page-link-number {
    height: 44px;
    width: 48px;

    @include media-breakpoint-up(md) {
      height: 40px;
      width: 40px;
    }
  }

  .step-links {
    @include media-breakpoint-between(xs, md) {
      flex-wrap: wrap;
    }
  }
}
