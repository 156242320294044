// Reboot
//
// Normalization of HTML elements, inspired by Bootstrap's _reboot.scss file.

html {
  position: relative;
}

body {
  background-color: $white;
  color: $darker-gray-text;
  font-family: "Lato", sans-serif;
  font-size: 1.125rem;

  // Handle dynamic viewports in newer browsers. vh is fallback.
  min-height: 100vh;

  // noinspection CssInvalidPropertyValue
  min-height: 100dvh;
  print-color-adjust: exact;
  scroll-behavior: smooth;

  @include media-breakpoint-up(md) {
    background-color: $slate-gray;
  }

  @include media-breakpoint-up(lg) {
    min-height: 100vh;
  }
}

main {
  flex: 1 auto;
}

// Typography

h1 {
  @extend %base-h1;
}

h2 {
  @extend %base-h2;
}

h3 {
  @extend %base-h3;
}

h4 {
  @extend %base-h4;
}

// Forms

input,
textarea {
  color: $darker-gray-text;

  &::placeholder {
    color: $lighter-gray-text;
  }
}

label {
  // NOTE: Boostrap 5 removed this from label and moved it to the `form-label` class
  &:not(.btn) {
    margin-bottom: 0.5rem;
  }
}

textarea {
  @include el-border;

  border-radius: $border-radius-lg;
  height: 180px;
  padding: 10px;
  resize: none;
  width: 100%;
}

// Links

// NOTE: We've disabled link hover for now
a {
  &:not(.btn) {
    --#{$prefix}link-color-rgb: var(--primary-brand-color-rgb);
    --#{$prefix}link-hover-color-rgb: var(--primary-brand-color-rgb);
  }
}
