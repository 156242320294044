.announcements {
  bottom: 75px;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: $zindex-toast;

  @include media-breakpoint-up(md) {
    bottom: 40px;
    left: 40px;
    width: 335px;
  }

  .announcement {
    background-color: $white;
    border-left: 2px solid $secondary-brand-color;
    border-right: 2px solid $secondary-brand-color;
    border-top: 2px solid $secondary-brand-color;
    padding: 20px;
    position: relative;

    &:last-child {
      border-bottom: 2px solid $secondary-brand-color;
      margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
      border: 2px solid $secondary-brand-color;
      border-radius: $border-radius-lg;
      margin-bottom: 20px;
    }

    h3 {
      padding-right: 20px;
    }

    img {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
}

// Despite the name, this is definitely a toast!

.success-modal-container {
  background-color: $white;
  border: 1px solid $secondary-brand-color;
  border-radius: $border-radius-lg;
  box-shadow: 0 10px 50px -10px $black-with-10-percent-opacity;
  left: 20px;
  position: fixed;
  right: 20px;
  text-align: center;
  top: 40px;
  width: calc(100% - 40px);
  z-index: $zindex-toast;

  @include media-breakpoint-up(md) {
    left: auto;
    right: 40px;
    width: 335px;
  }

  &.large {
    left: 20px;
    right: 40px;
    top: 20px;
    width: calc(100% - 40px);

    @include media-breakpoint-up(md) {
      left: calc(50% - 250px);
      right: auto;
      top: 200px;
      width: 500px;
    }

    .success-modal {
      padding: 40px;
      text-align: center;

      .btn {
        @include media-breakpoint-between(xs, md) {
          min-width: auto;
          width: 100%;
        }
      }

      .success-modal-content {
        @include media-breakpoint-between(xs, md) {
          align-items: center;
          flex-direction: column;

          div {
            margin-left: 0;
          }

          .success-envelope-stars {
            margin-bottom: 10px;
            width: auto;
          }
        }
      }
    }
  }

  .success-modal {
    box-shadow: -1px -1px 3px 0 $purple-with-10-percent-opacity;
    height: 100%;
    padding: 10px;
    width: 100%;

    @include media-breakpoint-between(xs, md) {
      font-size: 1rem;
      text-align: left;
    }

    @include media-breakpoint-up(md) {
      padding: 20px;
    }

    .success-modal-content {
      align-items: center;
      justify-content: center;

      @include media-breakpoint-up(md) {
        flex-direction: column;
        justify-content: flex-start;
      }

      div {
        margin-left: 10px;

        @include media-breakpoint-up(md) {
          align-items: center;
          margin-left: 0;
        }
      }
    }

    .btn {
      width: 100%;
    }
  }

  .close-success-modal {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    width: 14px;
  }

  .success-envelope-stars {
    width: 80px;

    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
      width: auto;
    }
  }

  .success-heart-hands {
    @include media-breakpoint-up(md) {
      margin-bottom: 10px;
      width: 40px;
    }
  }
}
