.exit-popup-container {
  animation: visible 300ms forwards;
  background-color: $black-with-85-percent-opacity;
  display: block;
  height: 100vh;
  height: 100dvh;
  left: 0;
  max-height: 100vh;
  max-height: 100dvh;
  position: fixed;
  top: 0;
  width: 100vw;
  width: 100dvw;
  z-index: $zindex-modal;

  @include media-breakpoint-up(md) {
    overflow-y: scroll;
    overscroll-behavior-y: contain;
  }

  @include media-breakpoint-up(lg) {
    height: 100vh;
    max-height: 100vh;
  }

  &.d-none {
    display: none;
  }

  .exit-popup {
    align-items: center;
    animation: slide-down 300ms forwards;
    background-color: $white;
    box-shadow: $card-shadow;
    display: flex;
    flex-direction: column;
    height: 100vh;
    height: 100dvh;
    justify-content: flex-start;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
    padding: 20px;
    position: relative;
    width: 100%;

    @include media-breakpoint-up(md) {
      border-radius: $border-radius-lg;
      height: auto;
      justify-content: center;
      margin: 100px auto 40px;
      overflow-y: auto;
      overscroll-behavior-y: auto;
      width: 700px;
    }

    .popup-close {
      color: $dark-gray-text;
      font-size: 2.5rem;
      font-weight: $font-weight-semibold;
      position: absolute;
      right: 20px;
      top: 20px;

      @include media-breakpoint-up(md) {
        font-size: 1rem;
      }
    }
  }
}

.modal-crop {
  @include media-breakpoint-up(md) {
    margin-top: 60px;
  }

  &.modal {
    .modal-dialog {
      @include media-breakpoint-between(xs, md) {
        margin: 0;
      }

      .modal-body {
        @include media-breakpoint-between(xs, md) {
          padding: 15px 0;
        }

        .image-container {
          @include media-breakpoint-between(xs, md) {
            height: calc(100vh - 300px);
          }
        }
      }
    }
  }

  .js-zoom-in,
  .js-zoom-out {
    cursor: pointer;
    min-width: auto;

    @include media-breakpoint-between(xs, md) {
      min-width: 120px;
    }
  }
}

.modal-mobile-full-screen {
  .modal-dialog {
    @include media-breakpoint-between(xs, md) {
      height: 100%;
      margin: 0;
    }

    .modal-content {
      @include media-breakpoint-between(xs, md) {
        border: 0;
        border-radius: 0;
        box-shadow: none;
        height: 100%;
      }
    }
  }
}

.subscribe-capture {
  form {
    img {
      border-radius: $border-radius-lg;
      margin-bottom: 20px;
      width: 100%;

      @include media-breakpoint-up(md) {
        flex: 0 0 320px;
        height: 240px;
        margin: 0 20px 0 0;
        width: 320px;
      }
    }

    .subscribe-capture-form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      @include media-breakpoint-up(md) {
        flex: 1 1 auto;
      }

      h2,
      p {
        margin: 0;
      }

      p {
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }

      .alert {
        margin: 0;
      }
    }

    button {
      width: 100%;
    }

    .el-animated-placeholder-label-input {
      width: 100%;

      input {
        width: 100%;
      }
    }
  }
}
