// Django messages

.messages-container {
  margin: 40px auto 0;

  @include media-breakpoint-between(xs, md) {
    margin-top: 20px;
    padding: 0;
  }

  &.sticky-messages {
    position: sticky;
    top: 0;
    z-index: $zindex-sticky;
  }

  .alert {
    @include media-breakpoint-between(xs, md) {
      --#{$prefix}alert-border-radius: 0;
    }
  }
}
