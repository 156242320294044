.btn-group-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 40px;

  @include media-breakpoint-up(md) {
    flex-direction: row;
  }

  > .btn {
    flex-basis: calc(50% - 8px);
    margin-bottom: 10px !important;
    max-width: none;
    min-width: auto;

    @include media-breakpoint-up(md) {
      margin: 0 !important;
    }
  }
}

.btn-group-toggle {
  width: 100%;

  .btn {
    flex: 1 1 0;
  }
}
