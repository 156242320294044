.sitemap-body {
  background-color: $white;
}

.sitemap-container {
  margin: 0 auto;
  max-width: 1165px;
  padding: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    padding: 40px 0 40px 40px;
  }

  h1 {
    margin-bottom: 0;
    padding: 20px 20px 0;

    @include media-breakpoint-up(md) {
      margin-bottom: 40px;
      padding: 0;
    }
  }
}

.sitemap-links-container {
  flex-direction: column;
  height: auto;

  @include media-breakpoint-up(md) {
    height: 2960px;
  }

  @include media-breakpoint-up(lg) {
    height: 2250px;
  }

  .link-group {
    @include el-border-bottom;

    padding: 0 20px;
    width: 100%;

    @include media-breakpoint-up(md) {
      border-bottom: 0;
      padding: 0 40px 0 0;
      width: 50%;
    }

    @include media-breakpoint-up(lg) {
      width: 33.3%;
    }

    &:last-of-type {
      border-bottom: 0;
    }
  }

  h3 {
    margin-top: 40px;

    @include media-breakpoint-up(md) {
      margin-top: 0;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;

    li {
      margin: 0 0 20px;

      &:last-of-type {
        margin-bottom: 40px;
      }
    }
  }
}
