// CTAs

.cta {
  margin-bottom: 0 !important;

  &.restore-margin {
    margin-bottom: 40px !important;
  }

  h2,
  p {
    text-align: center;
  }

  p {
    &:last-of-type {
      margin-bottom: 40px;
    }
  }

  .cta-btn-group {
    margin: 20px auto 0;
    text-align: center;

    .btn {
      max-width: $el-button-length;
      width: 100%;
    }
  }
}

.hollow-cta-mini {
  font-size: 0.875rem;
  letter-spacing: 0.2px;

  img {
    padding-bottom: 2px;
    width: 16px;
  }
}

.mini-cta {
  h2,
  p {
    text-align: left;
  }
}

// Login and signup

.passwordless-signup {
  .el-input-group {
    margin-bottom: 20px !important;
  }

  .borderless-input {
    max-width: 100%;
    min-width: auto;
    width: 100%;
  }

  .name-fields {
    display: flex;
    justify-content: space-between;

    @include media-breakpoint-between(xs, md) {
      flex-direction: column;
    }

    > div {
      flex-basis: calc(50% - 8px);

      @include media-breakpoint-between(xs, md) {
        margin-top: 10px;
      }
    }
  }

  .email-field {
    width: 100%;
  }
}

// Policy pages

.policy-container {
  @include media-breakpoint-between(xs, md) {
    ul {
      padding-left: 20px;
    }
  }
}

// Photo editing

.edit-photo {
  align-items: baseline;
  display: flex;
  justify-content: center;
  margin-top: -52px;

  .edit-photo-btn {
    background-color: $white;
    border-radius: 25.5px;
    display: inline-block;
    font-weight: 600;
    margin-bottom: 10px;
    opacity: 0.9;
    padding: 7px 15px;

    img {
      position: relative;
      top: -1px;
    }
  }
}

.profile-upload-container {
  border-radius: $border-radius-lg;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;

  @include media-breakpoint-up(md) {
    height: 319.53px;
    width: 425px;
  }

  @include media-breakpoint-up(lg) {
    display: block;
    margin-bottom: 0;
  }

  @include media-breakpoint-between(xs, md) {
    .profile-upload-photo-container {
      margin-bottom: 0;

      .image-preview {
        max-height: 100%;
        max-width: 100%;
        width: 100%;
      }
    }
  }

  .photo-controls {
    margin: 10px 0;

    .move-alt-photo {
      align-items: center;
      display: flex;
      flex-direction: row;

      &.disabled {
        img {
          filter: brightness(0) saturate(100%) $svg-recolor-darker-gray;
        }
      }

      &.remove-btn {
        white-space: nowrap;
      }
    }
  }
}

.profile-upload-photo-container {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;
}

// Uncategorized components

.add-button,
.remove-button {
  margin-right: 6px;
  margin-top: -2px;
}

.caret {
  &:checked {
    ~ label {
      filter: var(--primary-brand-color-svg);
    }
  }

  ~ label {
    filter: var(--primary-brand-color-svg);
  }
}

.column-section,
.profile-section-details {
  @include el-light-border-bottom;
  @include el-light-border-top;

  padding: 40px 20px;

  @include media-breakpoint-up(md) {
    padding: 40px 50px;
  }

  .column-section__header {
    align-items: center;
    display: flex;
    margin-bottom: 10px;

    &.column-section__header--no-margin-bottom {
      margin-bottom: 0;
    }

    h2 {
      margin-bottom: 0;
    }

    h3 {
      margin-bottom: 0;
    }

    img {
      margin-right: 10px;
    }
  }

  .column-section__subheader {
    margin-top: 20px;
    word-break: break-word;
    word-wrap: break-word;

    &.column-section__subheader-no-margin-top {
      margin-top: 0;
    }
  }

  .column-section__link-wrapper {
    margin-top: 20px;

    .sidebar-btn-block {
      margin: 0 0 20px;
    }
  }

  .column-section__divider--wide {
    border-bottom: 4px solid;
    margin-top: 20px;
    width: 50px;
  }

  .column-section__content {
    margin-top: 20px;
  }

  &:last-of-type {
    @include el-light-border-bottom;

    @include media-breakpoint-up(md) {
      border-bottom: 0;
    }
  }

  &.column-section--right-aligned {
    padding-left: 40px;
    padding-right: 0;
    padding-top: 0;
  }

  &.column-section--margin-bottom {
    margin-bottom: 40px;
  }

  // ----- Highlighted module sections -----
  &:not(.column-section--highlighted) {
    border-top: 0;
  }

  &.column-section--highlighted {
    &.start-highlight {
      border-top: 4px solid currentcolor;

      &:not(.column-section--margin-bottom) {
        margin-bottom: -1px; // collapse border
      }

      &.post-highlighted-section {
        border-top: 0;
        margin-bottom: 0;
      }

      &:first-of-type {
        @include media-breakpoint-only(md) {
          border-top: 0;
        }
      }

      &:not(.start-highlight--with-border) {
        border-top: 0;
      }
    }

    &.end-highlight {
      border-bottom: 4px solid currentcolor;
      margin-top: -1px; // collapse border

      &.pre-highlighted-section {
        border-bottom: 0;
        margin-top: 0;
      }
    }
  }
}

.column-section {
  @include media-breakpoint-up(lg) {
    padding: 40px 20px;
  }
}

.error {
  input {
    border: 0.5px solid $red;
    box-shadow: 0 0 1.5px 1px $red;
  }
}

.icon-see-more {
  margin-left: 6px;
}

.payment-help-container {
  img {
    flex-shrink: 0;
    margin-right: 10px;
    margin-top: 3px;
  }
}

.public-view-nav-item {
  @include media-breakpoint-between(xs, lg) {
    font-size: 0.8375rem;
  }
}

.remove-event,
.remove-faq {
  input {
    &[type="checkbox"] {
      display: none;
    }
  }
}

.sharing-url {
  font-weight: $font-weight-light;
  margin-right: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sidebar {
  flex-direction: column;
  height: 100%;
  max-width: 375px;
  position: sticky;
  top: 0;
  width: 100%;

  .column-section {
    &:first-child {
      &.column-section--highlighted {
        margin-top: 40px;
        padding-top: 40px;
      }
    }
  }
}

.sku {
  color: $primary-brand-color;
  font-family: monospace;
}

.sticky-sidebar {
  position: sticky;
  top: 40px;
}

.user-profile-default {
  .user-profile-initials {
    dominant-baseline: central;
    text-anchor: middle;
  }
}

.vault {
  .list-group {
    max-height: 250px;
    overflow-y: scroll;
    overscroll-behavior-y: contain;
  }

  .list-group-item {
    transition: all 200ms ease-in-out;

    img {
      height: 1rem;
      width: 1rem;
    }
  }
}

.flow-step-button {
  background-color: $white;
  border-color: $border-color;
  transition: all 150ms ease-in;

  &:hover {
    border-color: var(--secondary-brand-color) !important;
    box-shadow: 0 0 5px -1px var(--secondary-brand-color);
    transform: translateY(-3px) scale(1.002);
  }
}

.htmx-body-card {
  @include media-breakpoint-up(md) {
    position: relative;
  }

  .working-overlay {
    background-color: $white;
    border-radius: $border-radius;
    display: none;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: 0;
    transition: opacity 200ms ease-in-out;
    width: 100%;
    z-index: $zindex-fixed;

    @include media-breakpoint-up(md) {
      position: absolute;
    }

    &.htmx-request {
      display: block;
      min-height: 40vh;
      opacity: 0.95;

      @include media-breakpoint-up(md) {
        opacity: 1;
      }
    }
  }
}

.spinner-border-xl {
  height: 5rem;
  width: 5rem;
}

.expandable-collapse {
  --expandable-collapse-collapsed-height: 40px;
  --expandable-collapse-shadow-color: #{$white};

  padding-bottom: 1.5rem;
  position: relative;

  &.short {
    padding-bottom: 0;

    .control {
      display: none;
    }

    &::after {
      opacity: 0;
    }
  }

  &:not(.show) {
    display: block;
    height: var(--expandable-collapse-collapsed-height);
    overflow: hidden;

    &::after {
      opacity: 1;
    }
  }

  &::after {
    background-image: linear-gradient(
      transparent 0%,
      var(--expandable-collapse-shadow-color) 100%
    );
    bottom: 0;
    content: "";
    height: var(--expandable-collapse-gradient-height);
    left: 0;
    opacity: 0;
    position: absolute;
    transition: opacity ease-in-out 300ms;
    width: 100%;
  }

  .control {
    bottom: -8px;
    display: block;
    position: absolute;
    width: 100%;
    z-index: 2;

    a {
      background-color: var(--expandable-collapse-shadow-color);
      display: block;
      margin-left: auto;
      margin-right: auto;
      padding: 1px;
      text-align: center;
      transition: border ease-in-out 300ms;
      width: 8rem;

      &[aria-expanded="false"] {
        box-shadow: 0 0 1rem 0.5rem var(--expandable-collapse-shadow-color);

        .more {
          display: inline !important;
        }

        .less {
          display: none !important;
        }
      }

      &[aria-expanded="true"] {
        border: 1px solid var(--primary-brand-color);
        border-radius: 3px;

        .more {
          display: none !important;
        }

        .less {
          display: inline !important;
        }
      }
    }
  }

  &.collapsing {
    height: var(--expandable-collapse-collapsed-height);
  }
}
