.image-container {
  img {
    max-width: 100%;

    @include media-breakpoint-up(md) {
      max-height: 60vh;
    }
  }
}

.image-preview {
  background-size: cover;
  border-radius: $border-radius-lg;
  max-width: 425px;
  vertical-align: middle;

  @include media-breakpoint-up(md) {
    min-width: 425px;
  }
}
