.header-breadcrumbs {
  ol {
    font-size: 18px;
    margin-bottom: 40px;
    padding: 0;
  }

  li {
    display: inline;
  }
}
