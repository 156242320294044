// cormorant-garamond-regular - latin
@font-face {
  font-display: swap; // Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options.
  font-family: "Cormorant Garamond";
  font-style: normal;
  font-weight: $font-weight-normal;
  src:
    local(""),
    // Chrome 36+, Opera 23+, Firefox 39+
    url("../fonts/cormorant-garamond/cormorant-garamond-v16-latin-regular.woff2")
      format("woff2"),
    // Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+
    url("../fonts/cormorant-garamond/cormorant-garamond-v16-latin-regular.woff")
      format("woff");
}

// lato-300 - latin
@font-face {
  font-display: swap; // Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options.
  font-family: "Lato";
  font-style: normal;
  font-weight: $font-weight-light;
  src:
    local(""),
    // Chrome 36+, Opera 23+, Firefox 39+
    url("../fonts/lato/lato-v23-latin-300.woff2") format("woff2"),
    // Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+
    url("../fonts/lato/lato-v23-latin-300.woff") format("woff");
}

// lato-regular - latin
@font-face {
  font-display: swap; // Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options.
  font-family: "Lato";
  font-style: normal;
  font-weight: $font-weight-normal;
  src:
    local(""),
    // Chrome 36+, Opera 23+, Firefox 39+
    url("../fonts/lato/lato-v23-latin-regular.woff2") format("woff2"),
    // Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+
    url("../fonts/lato/lato-v23-latin-regular.woff") format("woff");
}

// lato-700 - latin
@font-face {
  font-display: swap; // Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options.
  font-family: "Lato";
  font-style: normal;
  font-weight: $font-weight-semibold;
  src:
    local(""),
    // Chrome 36+, Opera 23+, Firefox 39+
    url("../fonts/lato/lato-v23-latin-700.woff2") format("woff2"),
    // Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+
    url("../fonts/lato/lato-v23-latin-700.woff") format("woff");
}

// gelasio-regular - latin
@font-face {
  font-display: swap; // Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options.
  font-family: "Gelasio";
  font-style: normal;
  font-weight: 400;
  src:
    // Chrome 36+, Opera 23+, Firefox 39+
    url("../fonts/gelasio/gelasio-v10-latin-regular.woff2") format("woff2"),
    // Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+
    url("../fonts/gelasio/gelasio-v10-latin-regular.woff") format("woff");
}

// gelasio-700 - latin
@font-face {
  font-display: swap; // Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options.
  font-family: "Gelasio";
  font-style: normal;
  font-weight: 700;
  src:
    // Chrome 36+, Opera 23+, Firefox 39+
    url("../fonts/gelasio/gelasio-v10-latin-700.woff2") format("woff2"),
    // Chrome 5+, Firefox 3.6+, IE 9+, Safari 5.1+
    url("../fonts/gelasio/gelasio-v10-latin-700.woff") format("woff");
}
