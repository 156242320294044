// Site header

.el-nav-overlay {
  background: $white;
  height: 0%;
  left: 0;
  opacity: 0;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  transition:
    opacity 0.35s,
    visibility 0.35s,
    height 0.35s;
  visibility: hidden;
  width: 100%;
  z-index: $zindex-popover;

  @include media-breakpoint-up(md) {
    z-index: $zindex-offcanvas;
  }

  &.open {
    height: 100%;
    opacity: 1;
    visibility: visible;
  }

  .container {
    align-items: flex-start;
    flex-direction: column;
    padding: 50px 20px 0;
  }

  h2 {
    font-weight: $font-weight-semibold;
  }

  ul {
    list-style: none;
  }

  li {
    padding-bottom: 30px;
    width: 100%;

    img {
      margin-right: 15px;
    }
  }

  hr {
    margin-bottom: 30px;
    margin-top: 0;
    width: 100%;
  }
}

.el-navbar-toggler {
  cursor: pointer;
  display: block;
  height: 28px;
  position: absolute;
  right: 10px;
  top: 10px;
  transition: opacity 0.25s ease;
  width: 24px;
  z-index: $zindex-offcanvas;

  @include media-breakpoint-up(md) {
    display: none;
    top: auto;
  }

  &.active {
    z-index: $zindex-popover;

    .top {
      background: $darker-gray;
      transform: translateY(8px) rotate(45deg);
    }

    .middle {
      background: $darker-gray;
      opacity: 0;
    }

    .bottom {
      background: $darker-gray;
      transform: translateY(-8px) rotate(-45deg);
    }
  }

  &.el-navbar-desktop-hamburger-toggler,
  &.el-navbar-mobile-toggler-notifications,
  &.el-navbar-mobile-toggler {
    span {
      background: var(--primary-brand-color);
      border: 0;
      border-radius: 1.5px;
      cursor: pointer;
      height: 3px;
      left: 0;
      position: absolute;
      top: 0;
      transition:
        transform 0.35s ease,
        opacity 0.35s ease;
      width: 24px;

      &:nth-of-type(1) {
        top: 3px;
      }

      &:nth-of-type(2) {
        top: 11px;
      }

      &:nth-of-type(3) {
        top: 19px;
      }
    }
  }

  &.el-navbar-mobile-toggler-notifications {
    right: 60px;
  }
}

.desktop-toggler-container {
  position: relative;

  .el-nav-option-toggler {
    width: 100%;

    .el-nav-dropdown {
      right: 20px;
    }
  }
}

.el-navbar-desktop-toggler {
  display: none;
  margin-left: 40px;
  position: relative;
  right: 0;

  img {
    margin-bottom: 1px;
    margin-left: 5px;
    transition: transform 0.35s ease;

    &.rotate-180 {
      transform: rotate(180deg);
    }

    &.notification-icon {
      height: 25px;
      margin-left: 0;
      margin-top: 3px;
      width: 25px;
    }
  }

  @include media-breakpoint-up(md) {
    display: block;
    margin-left: 30px;
  }
}

.el-nav-dropdown {
  @include el-border;

  background: $white;
  border-radius: $border-radius-sm;
  display: none;
  margin-top: 25px;
  position: absolute;
  right: -25px;
  width: 365px;
  z-index: $zindex-offcanvas; // $zindex-offcanvas is higher than $zindex-sticky but not $zindex-modal

  .dropdown-arrow {
    @include el-border-left;
    @include el-border-top;

    background: $white;
    height: 24px;
    position: absolute;
    right: 22px;
    top: -13px;
    transform: rotate(45deg);
    width: 24px;
    z-index: -1;

    &.previously-seen {
      background-color: $light-gray;
    }
  }

  .dropdown-content {
    max-height: 70vh;
    overflow-y: scroll;
  }

  a,
  form {
    @include el-border-bottom;

    display: block;
    padding: 20px;
    width: 100%;

    &:last-child {
      border: 0;
    }
  }

  &.extended-nav-menu {
    right: -20px;

    .dropdown-arrow {
      right: 19px;
    }
  }

  .non-link-menu-item {
    border-bottom: 1px solid $medium-gray;
    padding: 20px;

    a {
      padding: 0;
    }
  }
}

.el-navbar {
  padding: 0;

  .container {
    align-items: center;
    justify-content: space-between;
    padding-bottom: 0;
  }

  .navbar-icon {
    margin-right: 6px;
    margin-top: -2px;
  }

  .el-navbar-nav {
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
  }
}

.el-navbar-brand {
  align-items: center;
  margin-bottom: 0;
  width: 100%;

  @include media-breakpoint-up(md) {
    margin-bottom: 4px;
    width: auto;
  }

  .header-logo {
    img {
      margin-bottom: 2px;
      margin-left: 10px;
      max-width: 140px;

      @include media-breakpoint-up(md) {
        margin-bottom: 0;
        margin-left: 0;
      }

      @include media-breakpoint-up(lg) {
        max-width: none;
      }

      &.partner-logo {
        margin-bottom: 0;
        margin-top: 6px;
      }
    }
  }
}

.primary-nav {
  background-color: $white;
  border-bottom: 1px solid $medium-gray;
  height: 48px;

  @include media-breakpoint-up(md) {
    height: 60px;
  }

  .container {
    align-items: center;
    height: 100%;
  }

  &.primary-nav-wide {
    .container {
      max-width: 1280px !important;

      @include media-breakpoint-up(md) {
        padding-left: 40px !important;
        padding-right: 40px !important;
      }
    }
  }

  &.primary-nav-extra-wide {
    .container {
      max-width: 1360px !important;

      @include media-breakpoint-up(md) {
        padding: 0 40px !important;
      }
    }
  }

  &.primary-nav-full-width {
    .container {
      max-width: 100% !important;

      @include media-breakpoint-up(md) {
        padding: 0 25px !important;
      }
    }
  }

  &.primary-nav-tablet-full-width {
    .container {
      max-width: 100% !important;

      @include media-breakpoint-up(md) {
        padding: 0 25px !important;
      }

      @include media-breakpoint-up(lg) {
        max-width: 1095px !important;
        padding: 0 !important;
      }
    }
  }

  &.primary-nav-borderless {
    border-bottom: 0;
  }

  &.primary-nav-public {
    .container {
      max-width: 1480px !important;

      @include media-breakpoint-up(md) {
        padding: 0 40px !important;
      }
    }
  }

  .el-nav-link {
    font-weight: $font-weight-semibold;
    margin-left: 20px;
    padding-left: 0;
    padding-right: 0;

    @include media-breakpoint-up(lg) {
      margin-left: 40px;
    }
  }
}

.el-navbar-container {
  &.sticky-navbar {
    position: sticky;
    top: -140px; // Special size for very small phones
    z-index: $zindex-sticky;

    // Transition to the regular mobile layout here
    @media (min-width: 380px) {
      top: -120px;
    }

    @include media-breakpoint-up(md) {
      top: 0;
    }

    &.example {
      top: 0;
    }

    .el-nav-item {
      .btn {
        align-items: center;
        justify-content: center;
        margin: 0 0 0 20px;
        min-width: 240px;

        img {
          height: 16px;
          margin-left: 8px;
          position: relative;
          top: -3px;
          width: 18px;
        }
      }
    }

    .example-nav {
      background-color: $secondary-brand-color;
      color: $white;

      .container {
        margin: 0 auto;
        max-width: 1480px !important;
        padding: 0 40px;
      }

      .el-navbar-nav {
        margin-right: 0 !important;
      }
    }
  }
}

.secondary-nav {
  border-bottom: 1px solid $medium-gray;

  &.secondary-nav-public {
    .container {
      max-width: 1480px !important;

      @include media-breakpoint-up(md) {
        padding: 0 40px !important;
      }
    }
  }

  .el-navbar-collapse {
    justify-content: space-between;

    .el-nav-item {
      padding: 17.5px 0;
    }
  }
}

.memorial-context-switch {
  img {
    height: 14px;
    margin: -4px 8px 0 0;
    width: 14px;

    @include media-breakpoint-up(md) {
      height: auto;
      margin: -4px 6px 0 0;
      width: auto;
    }
  }

  .el-nav-item {
    border: 1px solid;
    border-radius: $border-radius-lg;
    font-size: 0.9375rem;
    justify-content: center;
    padding: 8px 16px !important;
    width: 100% !important;

    @include media-breakpoint-up(md) {
      border: 0;
      border-radius: 0;
      font-size: 1.125rem;
      justify-content: normal;
      padding: 17.5px 0 !important;
      width: auto;
    }
  }
}

.public-page-nav {
  background-color: $primary-brand-highlight-color;

  @include media-breakpoint-up(md) {
    background-color: $slate-gray;
  }

  .container {
    padding: 20px;

    @include media-breakpoint-up(md) {
      padding: 0 40px !important;
    }

    &.public-manager-share-container,
    &.public-missing-fundraiser-payment-container {
      padding: 4px !important;

      @include media-breakpoint-up(md) {
        padding: 0 40px !important;
      }

      .alert {
        margin-bottom: 0;

        @include media-breakpoint-up(md) {
          margin-bottom: 1rem;
        }
      }

      .light-gray-label {
        font-size: 0.75rem;
        margin-left: 2px;
      }
    }
  }

  .el-navbar-collapse {
    .el-navbar-nav {
      &:first-child {
        margin-bottom: 20px;

        @include media-breakpoint-up(md) {
          margin-bottom: 0;
          margin-right: 40px;
        }

        @include media-breakpoint-up(lg) {
          margin-right: 0;
        }

        img {
          margin-right: 10px;
          margin-top: 8px;
        }
      }

      .el-nav-item {
        align-items: flex-start;
        padding: 0;

        @include media-breakpoint-up(md) {
          padding: 17.5px 0;
        }
      }
    }
  }
}

.back-nav {
  .el-navbar-nav {
    align-items: center;
    padding: 10px 20px;

    @include media-breakpoint-up(md) {
      padding: 20px 0;
    }

    .el-nav-item {
      .el-nav-link {
        align-items: center;

        img {
          margin-right: 10px;
        }
      }
    }
  }
}

.memorial-feature-search {
  @include media-breakpoint-up(lg) {
    width: 200px;
  }

  @include media-breakpoint-up(xl) {
    width: 280px;
  }
}

.memorial-feature-search-mobile {
  right: 110px;
}

.memorial-search-mobile {
  margin-top: 20px;
}

.memorial-search {
  flex-grow: 1;
}

.unseen-notifications-dot {
  background-color: $private-alert-red;
  border-radius: 50%;
  height: 10px;
  left: 15px;
  position: absolute;
  top: 2px;
  width: 10px;
}

.notification-dropdown {
  .notifications-container {
    max-height: 560px;
    overflow-y: auto;
  }

  a {
    border: 0;
  }
}

.notifications-title-container {
  @include el-border-bottom;

  margin-top: 50px;
  padding: 0 20px;
}

.notification-container {
  @include el-border-bottom;

  padding: 20px;
  position: relative;

  @include media-breakpoint-up(md) {
    padding: 0;
  }

  &.previously-seen {
    background-color: $light-gray;
  }

  .dismiss-notification {
    bottom: 80%;
    font-size: 1.5rem;
    height: 20px;
    left: 94%;
    padding: 8px 0 0;
    position: absolute;
    width: 20px;

    @include media-breakpoint-up(md) {
      padding: 0;
    }
  }
}

.load-more-link-container {
  padding: 20px;
  text-align: center;

  @include media-breakpoint-up(md) {
    padding: 0;
  }
}

.empty-notifications-message {
  border: 0;
  font-size: 1.3125rem;
  margin-top: 50px;
  padding: 6rem 0 0;
  text-align: center;

  @include media-breakpoint-up(md) {
    font-size: 1.125rem;
    margin-top: 0;
    padding: 20px;
  }
}

.powered-by-header {
  color: $light-gray-text;
  font-size: 0.75rem;
  margin-left: 10px;
  position: relative;

  a {
    color: $light-gray-text !important;
  }

  img {
    bottom: 2px;
    position: relative;
  }
}

.partner-logo {
  max-height: 42px;
  max-width: 160px;
  object-fit: contain;

  @include media-breakpoint-up(md) {
    max-height: 54px;
  }
}

.header-memorial-search-container {
  height: 28px;

  .borderless-input {
    height: 28px;
    margin: 0 20px 0 0;
    width: 235px;
  }
}
