.body-text-large {
  font-size: 1.125rem;

  @include media-breakpoint-up(lg) {
    font-size: 1.3125rem;
  }
}

.dark-gray-label {
  @extend %base-label;

  color: $darker-gray-text;
  margin-bottom: 10px;
}

.disclaimer {
  color: $lighter-gray-text;
  font-size: 0.875rem;
  margin-top: 20px;
}

.light-gray-label {
  @extend %base-label;

  color: $light-gray-text;
}

.mini-section-title {
  letter-spacing: 0.2px;
  text-transform: uppercase;
}

.mini-section-title-blue {
  color: $blue;
  font-size: 0.875rem;
  font-weight: $font-weight-normal;
  width: 65%;
}

.page-header {
  padding: 20px 20px 0;

  @include media-breakpoint-up(md) {
    margin-top: 40px;
    padding: 0;
  }
}

.text-note {
  @extend %base-text-note;
}

.text-note-small {
  @extend %base-text-note;

  font-size: 0.875rem;
  text-transform: none;
}

.text-primary {
  color: var(--primary-brand-color) !important;
}

.text-secondary {
  color: var(--secondary-brand-color) !important;
}

.fs-7 {
  font-size: 0.875rem;
}
