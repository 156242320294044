// NOTE: We need this here because not all of our color themes have enough contrast
// for Bootstrap's color-contrast function to choose white as the foreground color
// when using the text-bg-{theme-color} utility classes.
.badge {
  &:not(.text-bg-light) {
    color: $white !important;
  }
}

.badge-bordered {
  box-shadow: inset 0 0 0 1px $gray-400;
  box-sizing: border-box;
}
